<template>
  <div class="information accordion-info contained">
    <div class="page-title">
      {{ pageTitle }}
    </div>

    <section class="sections">
      <section
        class="information-section"
        v-for="(section, sectionIndex) in sections"
        :key="sectionIndex">
        <b-jumbotron
          class="contained"
          :header="section.jumbotron.header"
          :lead="section.jumbotron.lead"/>
        <article
          class="information-article"
          :class="{'open' : openArticle == `${sectionIndex}-${articleIndex}`}"
          v-for="(article, articleIndex) in section.articles"
          :key="articleIndex">
          <div class="information-article-header">
            <div class="label">
              {{ article.label }}
            </div>
            <div class="article-header-button-wrapper">
              <b-button
                class="round"
                variant="outline-info"
                @click="toggleAccordion(sectionIndex, articleIndex)">
                <font-awesome-icon
                  :icon="[
                    'fal',
                     openArticle == `${sectionIndex}-${articleIndex}` ? 'minus' : 'plus'
                  ]"
                />
              </b-button>
            </div>
          </div>
          <div
            class="accordion-content"
            v-show="openArticle == `${sectionIndex}-${articleIndex}`">
            <div
              class="info-section"
              v-for="(infoSection, infoSectionIndex) in article.infoSections"
              :class="`info-section-type-${infoSection.type}`"
              :key="infoSectionIndex">
              <div
                class="label"
                :class="infoSection.type"
                v-if="infoSection.label">
                <span class="icon-wrapper">
                  <font-awesome-icon
                    :icon="infoSection.icon" />
                </span>
                <span class="label-text">{{ infoSection.label }}</span>
              </div>
              <div class="info-section-content">
                <ul class="info-section-points"
                  v-if="infoSection.points && infoSection.type !== 'button'">
                  <li v-for="(point, pointIndex) in infoSection.points"
                    :key="pointIndex">
                    {{ point }}
                  </li>
                </ul>
                <b-button
                    :href="infoSection.link"
                    variant="outline-info"
                    class="expanded"
                    v-if="infoSection.type == 'button'">
                    {{ infoSection.buttonText }}
                </b-button>
              </div>
            </div>
          </div>
        </article>

        <section
          class="contained action-button-container"
          v-if="!isApp && sectionIndex === 0"
        >
          <b-button
            :href="getMethodButton.link"
            variant="secondary"
            class="leading">
            <font-awesome-icon :icon="getMethodButton.icon"/>
            {{ getMethodButton.text }}
          </b-button>
        </section>
      </section>
    </section>
    <section
      class="contained action-button-container bottom-buttons"
      v-if="actionButton"
    >
      <b-button
          :href="actionButton.link"
          variant="primary"
          class="leading">
          <font-awesome-icon :icon="actionButton.icon"/>
          {{ actionButton.text }}
      </b-button>

      <b-button
        v-if="!isApp"
        :href="getMethodButton.link"
        variant="secondary"
        class="leading">
        <font-awesome-icon :icon="getMethodButton.icon"/>
        {{ getMethodButton.text }}
      </b-button>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Accordion',

  data: () => ({
    isApp: false, // TODO use .env
    pageTitle: 'recommendations',
    jumbotron: {
      header: 'Top recommendations for you',
      lead: 'Based on best fit and effectiveness. Click on each method to see pros and cons for you.',
    },
    sections: [
      {
        jumbotron: {
          header: 'Top recommendations for you',
          lead: 'Based on best fit and effectiveness. Click on each method to see pros and cons for you.',
        },
        articles: [
          {
            label: 'The intrauterine device (IUD)',
            infoSections: [
              {
                label: 'Ideal for you',
                type: 'ideal',
                icon: ['fas', 'check-circle'],
                points: [
                  'This method is easily stopped if you want to get pregnant in the near future',
                  'Your fertility will return immediately after stopping this method',
                  'This method will not affect your regular menstrual period',
                  'This method is safe to use if you have breast cancer',
                  'This method is safe to use if you have heart disease',
                  'This method is safe to use when on ARVs for HIV',
                ],
              },
              {
                label: 'Imperfect fit',
                type: 'imperfect',
                icon: ['fas', 'times-circle'],
                points: [
                  'None found',
                ],
              },
              {
                label: 'Things to consider',
                type: 'considerations',
                icon: ['fas', 'question-circle'],
                points: [
                  'None found',
                ],
              },
              {
                buttonText: 'More information',
                type: 'button',
                link: '#',
              },
            ],
          },
          {
            label: 'Female Condom',
            infoSections: [
              {
                label: 'Ideal for you',
                type: 'ideal',
                icon: ['fas', 'check-circle'],
                points: [
                  'This method is easily stopped if you want to get pregnant in the near future',
                  'Your fertility will return immediately after stopping this method',
                  'This method will not affect your regular menstrual period',
                  'This method is safe to use if you have breast cancer',
                  'This method is safe to use if you have heart disease',
                  'This method is safe to use when on ARVs for HIV',
                ],
              },
              {
                label: 'Imperfect fit',
                type: 'imperfect',
                icon: ['fas', 'times-circle'],
                points: [
                  'None found',
                ],
              },
              {
                label: 'Things to consider',
                type: 'considerations',
                icon: ['fas', 'question-circle'],
                points: [
                  'None found',
                ],
              },
              {
                buttonText: 'More information',
                type: 'button',
                link: '#',
              },
            ],
          },
          {
            label: 'Another one',
            infoSections: [
              {
                label: 'Ideal for you',
                type: 'ideal',
                points: [
                  'This method is easily stopped if you want to get pregnant in the near future',
                  'Your fertility will return immediately after stopping this method',
                  'This method will not affect your regular menstrual period',
                  'This method is safe to use if you have breast cancer',
                  'This method is safe to use if you have heart disease',
                  'This method is safe to use when on ARVs for HIV',
                ],
              },
              {
                label: 'Imperfect fit',
                type: 'imperfect',
                points: [
                  'None found',
                ],
              },
              {
                label: 'Things to consider',
                type: 'considerations',
                points: [
                  'None found',
                ],
              },
              {
                buttonText: 'More information',
                type: 'button',
                link: '#',
              },
            ],
          },
        ],
      },
      {
        jumbotron: {
          header: 'Other methods to consider',
          lead: 'The following methods are less effective or are less suited to you, based on your answers.',
        },
        articles: [
          {
            label: 'The intrauterine device (IUD)',
            infoSections: [
              {
                label: 'Ideal for you',
                type: 'ideal',
                points: [
                  'This method is easily stopped if you want to get pregnant in the near future',
                  'Your fertility will return immediately after stopping this method',
                  'This method will not affect your regular menstrual period',
                  'This method is safe to use if you have breast cancer',
                  'This method is safe to use if you have heart disease',
                  'This method is safe to use when on ARVs for HIV',
                ],
              },
              {
                label: 'Imperfect fit',
                type: 'imperfect',
                points: [
                  'None found',
                ],
              },
              {
                label: 'Things to consider',
                type: 'considerations',
                points: [
                  'None found',
                ],
              },
            ],
          },
          {
            label: 'Female Condom',
            infoSections: [
              {
                label: 'Ideal for you',
                type: 'ideal',
                points: [
                  'This method is easily stopped if you want to get pregnant in the near future',
                  'Your fertility will return immediately after stopping this method',
                  'This method will not affect your regular menstrual period',
                  'This method is safe to use if you have breast cancer',
                  'This method is safe to use if you have heart disease',
                  'This method is safe to use when on ARVs for HIV',
                ],
              },
              {
                label: 'Imperfect fit',
                type: 'imperfect',
                points: [
                  'None found',
                ],
              },
              {
                label: 'Things to consider',
                type: 'considerations',
                points: [
                  'None found',
                ],
              },
            ],
          },
          {
            label: 'Another one',
            infoSections: [
              {
                label: 'Ideal for you',
                type: 'ideal',
                points: [
                  'This method is easily stopped if you want to get pregnant in the near future',
                  'Your fertility will return immediately after stopping this method',
                  'This method will not affect your regular menstrual period',
                  'This method is safe to use if you have breast cancer',
                  'This method is safe to use if you have heart disease',
                  'This method is safe to use when on ARVs for HIV',
                ],
              },
              {
                label: 'Imperfect fit',
                type: 'imperfect',
                points: [
                  'None found',
                ],
              },
              {
                label: 'Things to consider',
                type: 'considerations',
                points: [
                  'None found',
                ],
              },
            ],
          },
        ],
      },
    ],
    openArticle: null,
    actionButton: {
      text: 'start again',
      link: '#',
      icon: ['fas', 'arrow-left'],
    },
    getMethodButton: {
      text: 'get a method',
      link: '#',
      icon: ['far', 'flag'], // TODO add flag icon
    },
  }),
  methods: {
    toggleAccordion(sectionIndex, articleIndex) {
      let uniqueKey = `${sectionIndex}-${articleIndex}`;
      if (this.openArticle === uniqueKey) {
        uniqueKey = null;
      }
      this.openArticle = uniqueKey;
    },
  },
};
</script>

<style lang="scss" scoped>
.questions {
  margin: 1.5rem 0;
}
</style>
